<template>
  <div class="csn-game">
    <GameLists
      :getList="getNewGameList"
      :itemsPerScreen="itemsPerScreen"
      :hasVericalUpperRow="true"
    />
  </div>
</template>

<script>
import { NEW_GAMES } from '@/constants'
import { cardsPerScreenCalculationMixin } from '@/mixins'
import { GameApi } from '@/api'

export default {
  name: NEW_GAMES,
  mixins: [cardsPerScreenCalculationMixin],
  components: {
    GameLists: () => import('@/components/GameLists'),
  },
  methods: {
    getNewGameList: GameApi.getNewGameList,
  },
}
</script>
